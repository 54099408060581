import React from 'react'
import PropTypes from 'prop-types'
import { themeFontSize, themeFontWeight, themeColor } from '@elparking/components'
import styled from 'styled-components'

const errorColorValue = ({errorColor, errors}) => typeof errorColor === 'function' ? themeColor(errorColor(errors)) : themeColor(errorColor)

export const Wrapper = ({ children }) => (
    <div>
        {React.Children.toArray(children)[0]}
    </div>
)

Wrapper.propTypes = {
    children: PropTypes.array,
}

export class Error extends React.PureComponent {
    render () {
        return <StyledError {...this.props} />
    }
}

const StyledError = styled.p`
    color: ${errorColorValue};
    font-size: ${themeFontSize('xxSmall')};
    font-weight: ${themeFontWeight('regular')};
    padding: 8px 0 0 0;
`

StyledError.propTypes = {
    errorColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    errors: PropTypes.object,
}

StyledError.defaultProps = {
    errorColor: 'red',
    errors: {},
}
