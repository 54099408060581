const DNI_REGEX = /^(\d{8})([A-Z])$/
const CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/
const NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/

function spainIdType (str) {
    if (str.match(DNI_REGEX)) {
      return 'dni'
    }
    if (str.match(CIF_REGEX)) {
      return 'cif'
    }
    if (str.match(NIE_REGEX)) {
      return 'nie'
    }
}

function validDNI (dni) {
   let dniLetters = 'TRWAGMYFPDXBNJZSQVHLCKE'
   let letter = dniLetters.charAt(parseInt(dni, 10) % 23)
        return letter === dni.charAt(8)
}

function validNIE (nie) {
    // Change the initial letter for the corresponding number and validate as DNI
   let niePrefix = nie.charAt(0)
    switch (niePrefix) {
      case 'X': niePrefix = 0; break
      case 'Y': niePrefix = 1; break
      case 'Z': niePrefix = 2; break
    }
    return validDNI(niePrefix + nie.substr(1))
}

function validCIF (cif) {
   let match = cif.match(CIF_REGEX)
   let letter = match[1]
   let number = match[2]
   let control = match[3]
   let evenSum = 0
   let oddSum = 0
   let n
    for (var i = 0; i < number.length; i++) {
      n = parseInt(number[i], 10)
      // Odd positions (Even index equals to odd position. i=0 equals first position)
      if (i % 2 === 0) {
        // Odd positions are multiplied first.
        n *= 2
        // If the multiplication is bigger than 10 we need to adjust
        oddSum += n < 10 ? n : n - 9
      // Even positions
      // Just sum them
      } else {
        evenSum += n
      }
    }
   let controlDigit = parseInt(10 - (evenSum + oddSum).toString().substr(-1))
   let controlLetter = 'JABCDEFGHI'.substr(controlDigit, 1)
    // Control must be a digit
    if (letter.match(/[ABEH]/)) {
        return parseInt(control) === (controlDigit % 10)
    // Control must be a letter
    } else if (letter.match(/[KPQS]/)) {
        return control === controlLetter
    // Can be either
    } else {
        return parseInt(control) === controlDigit || control === controlLetter
    }
}
export default function (item) {
    // Ensure upcase and remove whitespace
    let valid = false
    if (item) {
      const str = item.toUpperCase().replace(/\s/, '')
      let type = spainIdType(str)

      switch (type) {
          case 'dni':
            valid = validDNI(str)
            break
          case 'nie':
            valid = validNIE(str)
            break
          case 'cif':
            valid = validCIF(str)
            break
      }
    }
    return valid
}
