export default (cnpj = '') => {
    if (!cnpj) {
        return false
    }
    const cleaned = cnpj.toString().replace(/[./-]/g, '')

    if (
      !cleaned ||
      cleaned.length !== 14 ||
      /^(\d)\1+$/.test(cleaned)
    ) {
        return false
    }

    let registration = cleaned.substr(0, 12)
    registration += digit(registration)
    registration += digit(registration)

    return registration.substr(-2) === cleaned.substr(-2)
}

const digit = numbers => {
    let index = 2

    const reverse = numbers
      .split('')
      .reduce((buffer, number) => [+number].concat(buffer), [])

    const sum = reverse.reduce((buffer, number) => {
        buffer += number * index
        index = index === 9 ? 2 : index + 1
        return buffer
    }, 0)

    const mod = sum % 11

    return mod < 2 ? 0 : 11 - mod
}
