import validCnpj from './cnpj'
import validCpf from './cpf'
import validateCifnifnie from './cifnifnie'

const HTMLEntities = '\\u00A0-\\u9999<>\'"\\&%'

export const password = () => (val = '') => {
    return !!/[A-Z]+/.test(val) && !!/[a-z]+/.test(val) && !!/[0-9]+/.test(val) && (val.length >= 8) && (val.length < 100)
}
// Sync validators
export const plateNumber = () => (val = '') => /^((\d{4}([b-dB-D]|[f-hF-H]|[j-nJ-N]|[p-tP-T]|[v-zV-Z]){3})|([a-zA-Z]{1,2}\d{4}[a-zA-Z]{1,2})|([a-zA-Z]{1,2}\d{6})$)/.test(val)
export const maxBytesSize = (size) => (files) =>
!files || ([...files]).reduce((s, file) => s + file.size, 0) < size
export const checked = () => (val = false) => val
export const required = () => (val = '') => !!val && `${val}`.trim().length > 0
export const length = ({ min, max }) => (val = '') => !val || (val.length >= min && val.length <= max)
export const phone = () => (val = '') => /^(\+[0-9]+[-. ]*)?(\([0-9]+\)[-. ]*)?([0-9][0-9-. ]+[0-9])$/.test(val)
export const email = () => (val = '') => new RegExp('^[^' + HTMLEntities + ']+@[^' + HTMLEntities + ']+\\.[^' + HTMLEntities + ']+$', 'i').test(val)
export const number = () => (val = '') => /^[0-9]*$/.test(val)
export const postalCode = () => (val = '') => /^[0-9]{5}$/.test(val)
export const cifnifnie = () => (val = '') => validateCifnifnie(val)

// Async validators
export const available = ({ isAvailable }) => (val = '', done) => isAvailable(val).then(({ result: exists }) => done(!exists))
export const validCnpjOrCpf = () => (val = '') => validCnpj(val) || validCpf(val)
