const isDigit = (val) => {
    return /^\d$/.test(val)
}

const sum = (a, b) => {
    return a + b
}

const calc = (nums) => {
    return nums
        .map((num, i) => num * (9 - (i % 10)))
        .reduce(sum)
}

const isRepeated = (string) => {
    const firstChar = string.charAt(0)
    const regex = RegExp(`^${firstChar}+$`)

    return regex.test(string)
}

const checkDigist = (digits = []) => {
    const arrayDigits = Array.from(digits || [])

    if (!arrayDigits.every(isDigit) || digits.length !== 9) {
        throw new Error('Invalid digits')
    }

    const reversed = arrayDigits.reverse()
    const cd = []

    cd[0] = calc(reversed) % 11 % 10
    cd[1] = (calc([0, ...reversed]) + cd[0] * 9) % 11 % 10

    return cd
}

export default (cpfNumber, byLength = false) => {
    if (typeof cpfNumber !== 'string') return false

    const unformattedCpf = cpfNumber.replace(/\D/g, '')

    if (!unformattedCpf) return false
    if (isRepeated(unformattedCpf)) return false
    if (unformattedCpf.length !== 11) return false
    if (byLength && unformattedCpf.length === 11) return true

    const [, number, dv] = unformattedCpf.match(/^(\d{9})(\d{2})$/)
    const trueDv = checkDigist(number).join('')

    return dv === trueDv
}
